export const testimonials = [
  {
    quote: `This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality. The support is one of the absolute best I've ever had the pleasure of interacting with. Quick, courteous, and extremely helpful!`,
    author: "Adam Peterson",
    role: "Business Owner",
    image: "/assets/images/ts1-user.jpg",
  },
  {
    quote: `This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality. The support is one of the absolute best I've ever had the pleasure of interacting with. Quick, courteous, and extremely helpful!`,
    author: "Adam Peterson",
    role: "Business Owner",
    image: "/assets/images/ts1-user.jpg",
  },
  {
    quote: `This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality. The support is one of the absolute best I've ever had the pleasure of interacting with. Quick, courteous, and extremely helpful!`,
    author: "Adam Peterson",
    role: "Business Owner",
    image: "/assets/images/ts1-user.jpg",
  },
];

export const hometestimonials = [
  {
    quote: `GetBiz Solutions transformed our HR processes with their intuitive tools and outstanding support.`,
    author: "Alex Johnson",
    role: "HR Manager, TechInnovate Inc.",
    image: "/assets/images/ts1-user.jpg",
  },
  {
    quote: `The efficiency and ease of GetBiz Solutions have significantly improved our operations. Highly recommended!`,
    author: "Maria Lopez",
    role: "CEO, GreenWave Solutions",
    image: "/assets/images/ts1-user.jpg",
  },
  {
    quote: `We’ve relied on GetBiz Solutions for years, and they continue to deliver exceptional value and service.`,
    author: "David Lee",
    role: "Finance Director, BlueSky Corp.",
    image: "/assets/images/ts1-user.jpg",
  },
];

export const testimonials2 = [
  {
    id: 1,
    blockquote:
      "Beautifull template, nice code and easy to customize. Optimization and structure are very good for seo basics.",
    author: {
      name: "Adam Peterson",
      title: "Business Owner",
      imgSrc: "/assets/images/ts1-user.jpg",
    },
  },
  {
    id: 2,
    blockquote:
      "Beautifull template, nice code and easy to customize. Optimization and structure are very good for seo basics.",
    author: {
      name: "Adam Peterson",
      title: "Business Owner",
      imgSrc: "/assets/images/ts1-user.jpg",
    },
  },
  {
    id: 3,
    blockquote:
      "Beautifull template, nice code and easy to customize. Optimization and structure are very good for seo basics.",
    author: {
      name: "Adam Peterson",
      title: "Business Owner",
      imgSrc: "/assets/images/ts1-user.jpg",
    },
  },
];

export const testimonials3 = [
  {
    text: "Beautiful template, nice code and easy to customize. Optimization, structure and pages are very good for SEO basics.",
    author: "Thomas Johnson",
    role: "UI/UX designer",
    imgSrc: "/assets/images/demo-gradient/user-1.jpg",
  },
  {
    text: "This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality.",
    author: "Alice Watson",
    role: "3D artist",
    imgSrc: "/assets/images/demo-gradient/user-2.jpg",
  },
  {
    text: "A remarkable model, everything has been thought out with talent, the design, the responsiveness, the various adaptations.",
    author: "Peter Braun",
    role: "Business owner",
    imgSrc: "/assets/images/demo-gradient/user-3.jpg",
  },
  {
    text: "Beautiful template, nice code and easy to customize. Optimization, structure and pages are very good for SEO basics.",
    author: "Thomas Johnson",
    role: "UI/UX designer",
    imgSrc: "/assets/images/demo-gradient/user-1.jpg",
  },
  {
    text: "This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality.",
    author: "Alice Watson",
    role: "3D artist",
    imgSrc: "/assets/images/demo-gradient/user-2.jpg",
  },
  {
    text: "A remarkable model, everything has been thought out with talent, the design, the responsiveness, the various adaptations.",
    author: "Peter Braun",
    role: "Business owner",
    imgSrc: "/assets/images/demo-gradient/user-3.jpg",
  },
];

// export const testimonialsone = [
//   {
//     text: "Outstanding support, easy setup, and highly effective tools for remarkable business growth and increased productivity.",
//     author: "Thomas Johnson",
//     role: "Marketing Strategist",
//     imgSrc: "/assets/images/demo-gradient/user-1.jpg",
//   },
//   {
//     text: "User-friendly platform, excellent customer service, and significant improvements in business efficiency and performance.",
//     author: "Alice Watson",
//     role: "Project Manager",
//     imgSrc: "/assets/images/demo-gradient/user-2.jpg",
//   },
//   {
//     text: "Incredible impact, intuitive tools, and a supportive team that truly understands our business needs and drives success.",
//     author: "Peter Braun",
//     role: "Business Consultant",
//     imgSrc: "/assets/images/demo-gradient/user-3.jpg",
//   },
//   {
//     text: "Exceptional service, effortless implementation, and immediate improvements in our operational workflow and productivity.",
//     author: "Thomas Johnson",
//     role: "Product Development Lead",
//     imgSrc: "/assets/images/demo-gradient/user-1.jpg",
//   },
//   {
//     text: "Impressive results, quick deployment, and a noticeable increase in our overall business productivity, efficiency, and growth",
//     author: "Alice Watson",
//     role: "Sales Director",
//     imgSrc: "/assets/images/demo-gradient/user-2.jpg",
//   },
//   {
//     text: "Transformative solutions, seamless integration, and outstanding results for our entire team’s productivity and success.",
//     author: "Peter Braun",
//     role: "Business Analyst",
//     imgSrc: "/assets/images/demo-gradient/user-3.jpg",
//   },
// ];

export const testimonialsone = [
  {
    text: "Fantastic HRMS features, quick setup, and a supportive team that boosted our team’s efficiency and streamlined payroll tasks.",
    author: "Sarah Mitchell",
    role: "HR Manager",
    imgSrc: "/assets/images/demo-gradient/user-1.jpg",
  },
  {
    text: "Seamless chat integration, amazing customer support, and noticeable improvements in communication across our departments.",
    author: "John Edwards",
    role: "Customer Support Lead",
    imgSrc: "/assets/images/demo-gradient/user-2.jpg",
  },
  {
    text: "Campaign tools were a game-changer for our marketing efforts. Easy to use and helped us boost engagement significantly.",
    author: "Rachel Green",
    role: "Marketing Coordinator",
    imgSrc: "/assets/images/demo-gradient/user-3.jpg",
  },
  {
    text: "CMS software made content management smooth, saving us time and making content updates far more efficient than before.",
    author: "Chris Peterson",
    role: "Content Manager",
    imgSrc: "/assets/images/demo-gradient/user-1.jpg",
  },
  {
    text: "With the HRMS software, our recruitment process was optimized, and employee data is now easily accessible at all times.",
    author: "Emily Stone",
    role: "Recruitment Specialist",
    imgSrc: "/assets/images/demo-gradient/user-2.jpg",
  },
  {
    text: "Powerful campaigns software, easy integration, and helped us increase our outreach and improve lead management efficiently.",
    author: "David Hunt",
    role: "Digital Marketing Lead",
    imgSrc: "/assets/images/demo-gradient/user-3.jpg",
  },
];

export const testimonials4 = [
  {
    id: 1,
    delay: "0s",
    stars: 4.5,
    text: "“Really impressed with the service and the results!”",
    caption: "Rated 4.5 on Trustpilot",
  },
  {
    id: 2,
    delay: "0.15s",
    stars: 4.5,
    text: "“Excellent service for targeted lead generation.”",
    caption: "Rated 4.5 on Trustpilot",
  },
  {
    id: 3,
    delay: "0.3s",
    stars: 4.5,
    text: "“I have super enjoyed working with Resonance.”",
    caption: "Rated 4.5 on Trustpilot",
  },
];

export const testimonialspagestars = [
  {
    id: 1,
    delay: "0s",
    stars: 4.5,
    text: "“Really impressed with the service and the results!”",
    caption: "Rated 4.5 on Capterra ",
  },
  {
    id: 2,
    delay: "0.15s",
    stars: 4.5,
    text: "“Excellent service for targeted lead generation.”",
    caption: "Rated 4.5 on G2",
  },
  {
    id: 3,
    delay: "0.3s",
    stars: 4.5,
    text: "“I have super enjoyed working with Resonance.”",
    caption: "Rated 4.5 on Glassdoor",
  },
];

export const testimonialItems = [
  {
    imageSrc: "/assets/images/demo-corporate/testimonial-1.jpg",
    quote:
      "This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality. The support is the absolute best I've ever had the pleasure of interacting with. Quick, courteous, premium and extremely helpful!",
    author: "Adam Peterson",
    position: "Marketing Consultant",
  },
  {
    imageSrc: "/assets/images/demo-corporate/testimonial-2.jpg",
    quote:
      "Optimization and structure are very good for SEO basics. A remarkable model, everything has been thought out with talent, the design, the various adaptations. Beautiful template, nice code and easy to customize.",
    author: "Jordan Harvey",
    position: "SEO/SMO Expert",
  },
];

export const testimonialpage = [
  {
    imageSrc: "/assets/images/demo-corporate/testimonial-1.jpg",
    quote:
      "GetBiz Solutions has truly transformed our operations. Their flexible, transparent pricing helped us select the perfect plan. The demo was insightful, and the tools proved to be both intuitive and highly effective from start to finish. Outstanding support made the experience even better!",
    author: " Alex Morgan",
    position: "Operations Manager",
  }
];

export const testimonials5 = [
  {
    text: "Beautifull template, nice code and easy to customize. Optimization and structure are verry good for SEO basics.",
    author: "Thomas Johnson",
    position: "UI/ UX Designer",
    imgSrc: "/assets/images/demo-fancy/user-1.jpg",
    altText: "Image description is here",
    extraClass: "mt-50 mt-sm-0",
  },
  {
    text: "The support is one of the absolute best I've ever had the pleasure of interacting with. Quick, courteous, and extremely helpful!",
    author: "Emma Kande",
    position: "3D Artist",
    imgSrc: "/assets/images/demo-fancy/user-2.jpg",
    altText: "Image description is here",
    extraClass: "",
  },
  {
    text: "This template is so beautiful and has such wonderful new options. It is updated often which gives me even more quality.",
    author: "Peter Rebel",
    position: "Business Owner",
    imgSrc: "/assets/images/demo-fancy/user-3.jpg",
    altText: "Image description is here",
    extraClass: "",
  },
  {
    text: "A remarkable model, everything has been thought out with talent, the design, the responsiveness, the various adaptations.",
    author: "Alice Watson",
    position: "Product Designer",
    imgSrc: "/assets/images/demo-fancy/user-4.jpg",
    altText: "Image description is here",
    extraClass: "",
  },
];
